import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { InboundService } from '../../services/inbound-service/inbound.service';
import { UserService } from '../../services/user-service/user.service';
import { DateSelectorComponent } from '../../shared/components/date-selector/date-selector.component';
import { GridComponent } from '../../shared/components/grid/grid.component';
import { PaginatorComponent } from '../../shared/components/paginator/paginator.component';
import { GridSkeletonComponent } from '../../shared/skeleton/grid-skeleton/grid-skeleton.component';
import { PaginatorSkeletonComponent } from '../../shared/skeleton/paginator-skeleton/paginator-skeleton.component';
import { inboundSummeryViewHeader } from './inbound-header-columns';
@Component({
  selector: 'rcp-inbound-metrics',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DateSelectorComponent,
    ReactiveFormsModule,
    DropdownModule,
    GridComponent,
    PaginatorComponent,
    InputTextModule,
    GridSkeletonComponent,
    TooltipModule,
    CalendarModule,
    PaginatorSkeletonComponent,
  ],
  templateUrl: './inbound-metrics.component.html',
  styleUrl: './inbound-metrics.component.scss',
  providers: [DatePipe],
})
export class InboundMetricsComponent implements OnInit {
  @ViewChild(GridComponent) childComponent!: GridComponent;
  @ViewChild('paginator', { static: false }) paginator: PaginatorComponent;
  destroy$: Subject<boolean> = new Subject<boolean>();
  first = 0;
  rows = 50;
  totalRecords = 0;
  gridFilterAndSorting = true;
  gridSorting = true;
  gridFilter = true;
  headerColumns: any;
  selectedReceivedDate: Date[] = null;
  selectedInterfaceDate: Date[] = null;
  defaultStartDate: Date = null;
  defaultEndDate: Date = null;
  defaultInterfaceStartDate: Date = null;
  defaultInterfaceEndDate: Date = null;
  maxReceivedDate: Date = new Date();
  maxInterfaceDate: Date = new Date();
  maxDateOfFirstSelectionReceivedDate: Date | undefined;
  maxDateOfFirstSelectionInterfaceDate: Date | undefined;
  isClearLastReceivedDate = false;
  isClearInterfaceDate = false;
  isRequiredStatus = true;
  selectedOrderNumber: string = null;
  selectedClientSku: string = null;
  parentRowField: any;
  childRowField: any;
  gridData: any[] = [];
  inboundListViewData = [];
  innerChildRowFieldArray = [];
  receivedDateFromStringFormat: string = null;
  receivedDateToSringFormat: string = null;
  interfacedDateFromStringFormat: string = null;
  interfacedDateToSringFormat: string = null;
  welcomeLabel = 'Welcome';
  inboundPageTitle = 'Purchase Order Details';
  receivedDatePlaceholder = 'Received date';
  interfaceDatePlaceholder = 'Interface date';
  orderNumberPlaceholder = 'PO number';
  clientSkuPlaceholder = 'Client SKU';
  applyFiltersBtnLabel = 'Apply filters';
  resetFiltersBtnLabel = 'Reset filters';
  dateFormat = 'yyyy-MM-dd';
  disabledApplyBtnMsg = 'Please provide at least one input to proceed.';
  loading = false;
  gridSortData = [false, false, false, true, true, true, true, true];
  gridFilterData = [true, false, false, false, false, false, false, false];
  sortBy = null;
  sortDirection = null;
  constructor(
    private readonly datePipe: DatePipe,
    private readonly cdf: ChangeDetectorRef,
    private readonly inboundService: InboundService,
    private readonly userService: UserService
  ) {
    this.maxReceivedDate.setHours(23, 59, 59, 999);
    this.maxInterfaceDate.setHours(23, 59, 59, 999);
  }
  ngOnInit(): void {
    this.gridHeaderData();
    this.autoCallAPIwithTodayOnDashboardIconClick();
    this.getDataFromSessionStorage();
  }
  // Get the grid header data
  gridHeaderData() {
    this.headerColumns = inboundSummeryViewHeader;
  }
  autoCallAPIwithTodayOnDashboardIconClick() {
    if (this.userService.isClickedOnDashboardIcon) {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      this.defaultStartDate = todayStart;
      this.defaultEndDate = todayEnd;
      this.selectedReceivedDate = [this.defaultStartDate, this.defaultEndDate];
      this.clearDataInSessionStorage();
      this.selectedOrderNumber = null;
      this.selectedClientSku = null;
      this.selectedInterfaceDate = null;
      this.applyFilter();
      this.userService.isClickedOnDashboardIcon = false;
    } else {
      this.loading = false;
    }
  }
  getDataFromSessionStorage() {
    if (
      sessionStorage.getItem('orderNumber') ||
      sessionStorage.getItem('clientSKU') ||
      sessionStorage.getItem('receivedDateFrom') ||
      sessionStorage.getItem('receivedDateTo') ||
      sessionStorage.getItem('interfaceDateFrom') ||
      sessionStorage.getItem('interfaceDateTo')
    ) {
      if (sessionStorage.getItem('orderNumber')) {
        this.selectedOrderNumber = JSON.parse(
          sessionStorage.getItem('orderNumber')
        );
      }
      if (sessionStorage.getItem('clientSKU')) {
        this.selectedClientSku = JSON.parse(
          sessionStorage.getItem('clientSKU')
        );
      }
      if (
        sessionStorage.getItem('receivedDateFrom') &&
        sessionStorage.getItem('receivedDateTo')
      ) {
        const storedFromDate = JSON.parse(
          sessionStorage.getItem('receivedDateFrom')
        );
        const storedToDate = JSON.parse(
          sessionStorage.getItem('receivedDateTo')
        );
        this.selectedReceivedDate = [storedFromDate, storedToDate];
        this.defaultStartDate = new Date(storedFromDate);
        this.defaultEndDate = new Date(storedToDate);
      }
      if (
        sessionStorage.getItem('interfaceDateFrom') &&
        sessionStorage.getItem('interfaceDateTo')
      ) {
        const storedFromDate = JSON.parse(
          sessionStorage.getItem('interfaceDateFrom')
        );
        const storedToDate = JSON.parse(
          sessionStorage.getItem('interfaceDateTo')
        );
        this.selectedInterfaceDate = [storedFromDate, storedToDate];
        this.defaultInterfaceStartDate = new Date(storedFromDate);
        this.defaultInterfaceEndDate = new Date(storedToDate);
      }
      this.applyFilter();
    }
  }
  // Handle paginator event
  onPageChange(event?: any) {
    if (event) {
      const start = event.first;
      const end = event.first + event.rows;
      this.paginator.changeValueForPaginator(start);
      this.childComponent.handleParentEvent(start, end);
    } else {
      this.paginator?.changeValueForPaginator(this.first);
      this.childComponent.handleParentEvent(this.first, this.rows);
    }
  }
  setOrderNumber() {
    if (this.selectedOrderNumber) {
      if (this.selectedReceivedDate) {
        this.selectedReceivedDate = null;
        this.isClearLastReceivedDate = true;
      }
      if (this.selectedInterfaceDate) {
        this.selectedInterfaceDate = null;
        this.isClearInterfaceDate = true;
      }
      this.selectedClientSku = null;
    }
  }
  setClientSku() {
    if (this.selectedClientSku) {
      if (this.selectedReceivedDate) {
        this.selectedReceivedDate = null;
        this.isClearLastReceivedDate = true;
      }
      if (this.selectedInterfaceDate) {
        this.selectedInterfaceDate = null;
        this.isClearInterfaceDate = true;
      }
    }
    this.selectedOrderNumber = null;
  }
  setLastReceivedDate(value: Date[]) {
    this.selectedReceivedDate = value;
    if (this.selectedReceivedDate.length > 0) {
      if (this.selectedInterfaceDate) {
        this.isClearInterfaceDate = true;
      }
      this.selectedInterfaceDate = null;
      this.selectedOrderNumber = null;
      this.selectedClientSku = null;
    }
  }
  setInterfaceDate(value: Date[]) {
    this.selectedInterfaceDate = value;
    if (this.selectedInterfaceDate.length > 0) {
      if (this.selectedReceivedDate) {
        this.isClearLastReceivedDate = true;
      }
      this.selectedReceivedDate = null;
      this.selectedOrderNumber = null;
      this.selectedClientSku = null;
    }
  }
  clearLastReceivedDate(event: any) {
    this.selectedReceivedDate = null;
    this.isClearLastReceivedDate = false;
    this.cdf.detectChanges();
  }
  clearInterfaceDate(event: any) {
    this.selectedInterfaceDate = null;
    this.isClearInterfaceDate = false;
    this.cdf.detectChanges();
  }
  onStartDateSelectedOfReceivedDate(startDate: Date) {
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 31);
    maxDate.setHours(23, 59, 59, 999);
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    this.maxDateOfFirstSelectionReceivedDate =
      maxDate > today ? today : maxDate;
  }
  onStartDateSelectedOfInterfaceDate(startDate: Date) {
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 31);
    maxDate.setHours(23, 59, 59, 999);
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    this.maxDateOfFirstSelectionInterfaceDate =
      maxDate > today ? today : maxDate;
  }
  resetFiltersData() {
    if (this.selectedReceivedDate?.length > 0) {
      this.isClearLastReceivedDate = true;
    }
    if (this.selectedInterfaceDate?.length > 0) {
      this.isClearInterfaceDate = true;
    }
    this.selectedReceivedDate = null;
    this.selectedInterfaceDate = null;
    this.selectedOrderNumber = null;
    this.selectedClientSku = null;
    this.inboundListViewData = null;
    this.maxDateOfFirstSelectionReceivedDate = null;
    this.maxDateOfFirstSelectionInterfaceDate = null;
    this.gridData = [];
    this.totalRecords = 0;
    this.childComponent.emptyGridOnReset();
    this.clearDataInSessionStorage();
    this.cdf.detectChanges();
  }
  applyFilter() {
    this.totalRecords = 0;
    this.receivedDateFromStringFormat = null;
    this.receivedDateToSringFormat = null;
    this.interfacedDateFromStringFormat = null;
    this.interfacedDateToSringFormat = null;
    if (this.selectedReceivedDate) {
      this.receivedDateFromStringFormat = this.datePipe.transform(
        this.selectedReceivedDate[0],
        this.dateFormat
      );
      this.receivedDateToSringFormat = this.datePipe.transform(
        this.selectedReceivedDate[1],
        this.dateFormat
      );
    }
    if (this.selectedInterfaceDate) {
      this.interfacedDateFromStringFormat = this.datePipe.transform(
        this.selectedInterfaceDate[0],
        this.dateFormat
      );
      this.interfacedDateToSringFormat = this.datePipe.transform(
        this.selectedInterfaceDate[1],
        this.dateFormat
      );
    }
    const payLoad = {
      poNumber: this.selectedOrderNumber,
      clientSku: this.selectedClientSku,
      receivedDateFrom: this.receivedDateFromStringFormat,
      receivedDateTo: this.receivedDateToSringFormat,
      interfacedDateFrom: this.interfacedDateFromStringFormat,
      interfacedDateTo: this.interfacedDateToSringFormat,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      offset: 0,
      pageLimit: 1000,
    };
    this.getFilterData(payLoad);
  }
  sortChanges(event: any) {
    if (
      this.selectedOrderNumber ||
      this.selectedClientSku ||
      this.selectedReceivedDate ||
      this.selectedInterfaceDate
    ) {
      this.sortBy =
        event.field === 'rcvd_details' ? 'goods_rcvd_date' : event.field;
      this.sortDirection = event.orderDirection;
      const payLoad = {
        poNumber: this.selectedOrderNumber,
        clientSku: this.selectedClientSku,
        receivedDateFrom: this.receivedDateFromStringFormat,
        receivedDateTo: this.receivedDateToSringFormat,
        interfacedDateFrom: this.interfacedDateFromStringFormat,
        interfacedDateTo: this.interfacedDateToSringFormat,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        offset: 0,
        pageLimit: 1000,
      };
      this.getFilterData(payLoad);
    }
  }
  addDataInGridTable() {
    this.gridData = [];
    this.inboundListViewData.forEach((item: any) => {
      this.innerChildRowFieldArray = [];
      this.parentRowField = {
        client_sku: item.client_sku ? item.client_sku : '-',
        sku_description: item.sku_description ? item.sku_description : '-',
        po_number: this.getPONumberValue(item.po_number),
        rcvd_details:
          item.rcvd_details.length > 1
            ? '-'
            : this.getDateValue(item.rcvd_details[0]?.goods_rcvd_date),
        purchase_orders_dttm: item.purchase_orders_dttm
          ? item.purchase_orders_dttm
          : '-',
        goods_rcvd_qty: item.goods_rcvd_qty ? Number(item.goods_rcvd_qty) : 0,
        expected_qty: item.expected_qty ? Number(item.expected_qty) : 0,
        status: item.status + '%',
      };
      if (item.rcvd_details.length > 1) {
        item.rcvd_details?.forEach((childItem: any) => {
          this.childRowField = {
            rcvd_details: childItem.goods_rcvd_date
              ? childItem.goods_rcvd_date
              : '-',
            goods_rcvd_qty: childItem.goods_rcvd_qty
              ? childItem.goods_rcvd_qty
              : 0,
          };
          this.innerChildRowFieldArray.push({
            data: this.childRowField,
          });
        });
      }
      this.gridData.push({
        data: this.parentRowField,
        children: this.innerChildRowFieldArray,
      });
    });
    this.totalRecords = this.gridData.length;
  }
  getDateValue(dateValue: Date) {
    if (dateValue) {
      return dateValue;
    } else {
      return '-';
    }
  }
  getPONumberValue(poNumberValue: string) {
    if (poNumberValue) {
      return poNumberValue.includes('_')
        ? poNumberValue.slice(0, poNumberValue.lastIndexOf('_'))
        : poNumberValue;
    } else {
      return '-';
    }
  }
  getFilterData(payLoad: any) {
    this.gridData = [];
    this.loading = true;
    this.inboundService
      .getInboundList(payLoad)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.inboundListViewData = data.response;
          this.loading = false;
          if (data.response?.length >= 1) {
            this.setDataInSessionStorage();
          } else {
            this.clearDataInSessionStorage();
          }
          this.addDataInGridTable();
          this.onPageChange();
        },
        error: () => {
          this.loading = false;
          console.error('error in fetching data');
        },
      });
  }
  setDataInSessionStorage() {
    this.clearDataInSessionStorage();
    if (this.selectedOrderNumber) {
      sessionStorage.setItem('orderNumber', this.selectedOrderNumber);
    }
    if (this.selectedClientSku) {
      sessionStorage.setItem('clientSKU', this.selectedClientSku);
    }
    if (this.selectedReceivedDate) {
      sessionStorage.setItem(
        'receivedDateFrom',
        JSON.stringify(this.selectedReceivedDate[0])
      );
      sessionStorage.setItem(
        'receivedDateTo',
        JSON.stringify(this.selectedReceivedDate[1])
      );
    }
    if (this.selectedInterfaceDate) {
      sessionStorage.setItem(
        'interfaceDateFrom',
        JSON.stringify(this.selectedInterfaceDate[0])
      );
      sessionStorage.setItem(
        'interfaceDateTo',
        JSON.stringify(this.selectedInterfaceDate[1])
      );
    }
  }
  clearDataInSessionStorage() {
    sessionStorage.removeItem('orderNumber');
    sessionStorage.removeItem('clientSKU');
    sessionStorage.removeItem('receivedDateFrom');
    sessionStorage.removeItem('receivedDateTo');
    sessionStorage.removeItem('interfaceDateFrom');
    sessionStorage.removeItem('interfaceDateTo');
  }
}
