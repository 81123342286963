export const inboundSummeryViewHeader = [
  { field: 'client_sku', header: 'Client SKU' },
  { field: 'sku_description', header: 'Description' },
  { field: 'po_number', header: 'PO number' },
  { field: 'rcvd_details', header: 'Received date' },
  { field: 'purchase_orders_dttm', header: 'Interface date' },
  { field: 'goods_rcvd_qty', header: 'Received quantity' },
  { field: 'expected_qty', header: 'Expected quantity' },
  { field: 'status', header: 'Status' },
];
