import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class InboundService {
  radialUrl = environment.baseUrl;
  constructor(private readonly http: HttpClient) {}
  getInboundList(data) {
    return this.http.post<any>(this.radialUrl + '/listing/inbound', data);
  }
}
